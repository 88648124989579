import Card from "../../../../components/common/card/card";
import GenericSEO from "../../../../components/common/SEO";

import { DIABLO4_ROUTE } from "../../../../utils/consts";

export const SKILL_TREES = [
  {
    name: "Barbarian",
    url: DIABLO4_ROUTE + "/tools/skilltree/barbarian",
    icon: "/images/Diablo4/class_icons/barb.webp",
    iconAlt: "Barbarian Skill Tree icon",
    title: "Barbarian Skill Tree Calculator",
    description: "Plan your barbarians build!",
  },
  {
    name: "Druid",
    url: DIABLO4_ROUTE + "/tools/skilltree/druid",
    icon: "/images/Diablo4/class_icons/druid.webp",
    iconAlt: "Druid Skill Tree icon",
    title: "Druid Skill Tree Calculator",
    description: "Plan your druids build!",
  },
  {
    name: "Necromancer",
    url: DIABLO4_ROUTE + "/tools/skilltree/necromancer",
    icon: "/images/Diablo4/class_icons/necromancer.webp",
    iconAlt: "Necromancer Skill Tree icon",
    title: "Necromancer Skill Tree Calculator",
    description: "Plan your necromancers build!",
  },
  {
    name: "Rogue",
    url: DIABLO4_ROUTE + "/tools/skilltree/rogue",
    icon: "/images/Diablo4/class_icons/rogue.webp",
    iconAlt: "Rogue Skill Tree icon",
    title: "Rogue Skill Tree Calculator",
    description: "Plan your rogues build!",
  },
  {
    name: "Sorcerer",
    url: DIABLO4_ROUTE + "/tools/skilltree/sorcerer",
    icon: "/images/Diablo4/class_icons/sorcerer.webp",
    iconAlt: "Sorcerer Skill Tree icon",
    title: "Sorcerer Skill Tree Calculator",
    description: "Plan your sorcerers build!",
  },
];

export default function SkillTree() {
  return (
    <div className="flex justify-center">
      <GenericSEO
        title="Skill Tree Planner and Calculator"
        description="Diablo 4's skill trees are a comprehensive and in-depth system that
        allows players to customize their character's abilities and
        playstyles. Each character class in the game features a unique skill
        tree, with multiple branches and nodes representing active skills,
        passive skills, and enhancements."
      />

      <div className="flex flex-col items-center gap-5 border border-zinc-500 bg-zinc-800 py-2 drop-shadow-2xl md:w-3/4">
        <h1 className="font-bold">Skill Tree Planner and Calculator</h1>

        <div>
          <p className="px-12">
            Diablo 4&apos;s skill trees are a comprehensive and in-depth system
            that allows players to customize their character&apos;s abilities
            and playstyles. Each character class in the game features a unique
            skill tree, with multiple branches and nodes representing active
            skills, passive skills, and enhancements.
          </p>
        </div>

        <div className="md:flex">
          {SKILL_TREES.map((skill) => (
            <Card
              key={skill.name}
              link={skill.url}
              image={skill.icon}
              imageAlt={skill.iconAlt}
              title={skill.title}
              description={skill.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
