import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";
import React from "react";
import { DEAD_BY_DAYLIGHT_ROUTE, DIABLO4_ROUTE } from "../../utils/consts";

interface IGenericSERO {
  title: string;
  description: string;
}

export default function GenericSEO({ title, description }: IGenericSERO) {
  const router = useRouter();

  function setSubNavSEO(pageName: string) {
    let title = "";
    if (router.asPath.includes(DEAD_BY_DAYLIGHT_ROUTE))
      title = "Dead By Daylight | ";
    else if (router.asPath.includes(DIABLO4_ROUTE)) title = "Diablo 4 | ";
    else title = "";

    title += pageName;

    return title;
  }

  return <DefaultSeo title={setSubNavSEO(title)} description={description} />;
}
