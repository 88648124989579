import Link from "next/link";
import Image, { StaticImageData } from "next/image";

interface ICard {
  link: string;
  image: string | StaticImageData;
  imageAlt: string;
  imageHeight?: number;
  imageWidth?: number;
  title: string;
  description: string;
}

export default function Card({
  link,
  image,
  imageAlt,
  imageHeight = 250,
  imageWidth = 250,
  title,
  description,
}: ICard) {
  return (
    <Link
      key={title}
      href={link}
      className="m-2 flex flex-col items-center rounded-md border border-zinc-500 bg-zinc-800 p-4 hover:border-red-500 hover:text-red-500 md:w-1/4"
    >
      <Image
        src={image}
        alt={imageAlt}
        width={imageWidth}
        height={imageHeight}
      />

      <div className="text-xl">{title}</div>

      <p className="py-4">{description}</p>
    </Link>
  );
}
