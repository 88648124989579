import React from "react";
import { useRouter } from "next/router";
import Footer from "./common/footer";
import Navbar from "./common/navbar";

import DiabloNavbar from "./Diablo4/diabloNavbar";
import DeadByDaylightNavbar from "./DeadByDaylight/deadByDaylightNavbar";
import { DIABLO4_ROUTE, DEAD_BY_DAYLIGHT_ROUTE } from "../utils/consts";

function SubNav() {
  const router = useRouter();
  if (router.asPath.includes(DEAD_BY_DAYLIGHT_ROUTE))
    return <DeadByDaylightNavbar />;
  else if (router.asPath.includes(DIABLO4_ROUTE)) return <DiabloNavbar />;
  else return "";
}

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex min-h-screen min-w-fit flex-col bg-zinc-900 text-white">
      <Navbar />
      <SubNav />
      <div className="p-4">{children}</div>
      <Footer />
    </div>
  );
}
