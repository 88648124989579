import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { DIABLO4_ROUTE, DEAD_BY_DAYLIGHT_ROUTE } from "../../utils/consts";

export default function Navbar() {
  const router = useRouter();

  return (
    <div className="flex border-b py-1 text-xs">
      <div className="flex items-center divide-x">
        <Link
          href="/"
          className={`px-4 ${
            router.pathname == "/" ? "text-red-500" : ""
          } hover:text-red-500`}
        >
          Home
        </Link>

        <Link
          href={DIABLO4_ROUTE}
          className={`flex px-4 ${
            router.pathname.includes(DIABLO4_ROUTE) ? "text-red-500" : ""
          } hover:text-red-500`}
        >
          <Image
            src="/images/home/d4-icon.webp"
            alt="Diablo 4 icon"
            width={20}
            height={20}
          />
          Diablo 4
        </Link>

        <div className="flex items-center divide-x">
          <Link
            href={DEAD_BY_DAYLIGHT_ROUTE}
            className={`flex px-4 ${
              router.pathname.includes(DEAD_BY_DAYLIGHT_ROUTE)
                ? "text-red-500"
                : ""
            } hover:text-red-500`}
          >
            <Image
              src="/images/home/dbd-icon.webp"
              alt="Dead By Daylight icon"
              width={20}
              height={20}
            />
            Dead By Daylight
          </Link>
        </div>
      </div>
    </div>
  );
}
