import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import { SKILL_TREES } from "../../pages/diablo4/tools/skilltree";
import { CHARACTER_SKILLS } from "../../pages/diablo4/data/skills";
import { DIABLO4_ROUTE } from "../../utils/consts";

export default function DiabloNavbar() {
  const router = useRouter();

  return (
    <div className="text-md flex border-b bg-zinc-800 py-2">
      <div className="flex items-center divide-x">
        <Link
          href={DIABLO4_ROUTE}
          className={`px-4 ${
            router.pathname == DIABLO4_ROUTE ? "text-red-500" : ""
          } hover:text-red-500`}
        >
          D4 Toolkit Home
        </Link>

        <div className="group">
          <Link
            href={`${DIABLO4_ROUTE}/tools/skilltree`}
            className={`px-4 ${
              router.pathname == `${DIABLO4_ROUTE}/tools/skilltree`
                ? "text-red-500"
                : ""
            } hover:text-red-500`}
          >
            Skill Tree Calculator
          </Link>

          <div className="absolute z-[20] hidden group-hover:block">
            <ul className="mt-2 grid grid-cols-1 gap-1 rounded-md bg-zinc-900 p-2 px-2 md:grid-cols-2">
              {SKILL_TREES?.map((skill: any) => (
                <Link
                  key={skill?.name}
                  href={`${skill?.url}`}
                  className={`flex border bg-zinc-800 px-2 py-2 ${
                    router.asPath == skill?.url
                      ? "border-red-500 text-red-500"
                      : ""
                  } hover:border-red-500 hover:text-red-500`}
                >
                  <Image
                    src={skill?.icon}
                    alt={skill?.iconAlt}
                    width={30}
                    height={30}
                  />
                  {skill?.name}
                </Link>
              ))}
            </ul>
          </div>
        </div>

        <div className="group">
          <Link
            href={`${DIABLO4_ROUTE}/data/skills`}
            className={`px-4 ${
              router.pathname == `${DIABLO4_ROUTE}/data/skills`
                ? "text-red-500"
                : ""
            } hover:text-red-500`}
          >
            All Skills
          </Link>

          <div className="absolute z-[20] hidden group-hover:block">
            <ul className="mt-2 grid grid-cols-1 gap-1 rounded-md bg-zinc-900 p-2 px-2 md:grid-cols-2">
              {CHARACTER_SKILLS?.map((skill: any) => (
                <Link
                  key={skill?.name}
                  href={`${skill?.url}`}
                  className={`flex border bg-zinc-800 px-2 py-2 ${
                    router.asPath == skill?.url
                      ? "border-red-500 text-red-500"
                      : ""
                  } hover:border-red-500 hover:text-red-500`}
                >
                  <Image
                    src={skill?.icon}
                    alt={skill?.iconAlt}
                    width={30}
                    height={30}
                  />
                  {skill?.name}
                </Link>
              ))}
            </ul>
          </div>
        </div>

        <Link
          href={`${DIABLO4_ROUTE}/tools/map`}
          className={`px-4 ${
            router.pathname == `${DIABLO4_ROUTE}/tools/map`
              ? "text-red-500"
              : ""
          } hover:text-red-500`}
        >
          World Map
        </Link>

        <div className="group">
          <Link
            href={`${DIABLO4_ROUTE}/data`}
            className={`px-4 ${
              router.pathname == `${DIABLO4_ROUTE}/data` ? "text-red-500" : ""
            } hover:text-red-500`}
          >
            Data
          </Link>

          <div className="absolute z-[20] hidden group-hover:block">
            <ul className="mt-2 grid grid-cols-1 gap-1 rounded-md bg-zinc-900 p-2 px-2 md:grid-cols-2">
              <Link
                href={`${DIABLO4_ROUTE}/data/aspects`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DIABLO4_ROUTE}/data/aspects`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Aspects
              </Link>

              <Link
                href={`${DIABLO4_ROUTE}/data/malignant-hearts`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DIABLO4_ROUTE}/data/malignant-hearts`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Malignant Hearts
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
