export enum TierTypes {
  KILLER_PERKS = "killer-perks",
  SURVIVOR_PERKS = "survivor-perks",
  KILLERS = "killers",
}

export const PerkTiersRoles = {
  [TierTypes.KILLER_PERKS]: "killer",
  [TierTypes.SURVIVOR_PERKS]: "survivor",
  [TierTypes.KILLERS]: "killer",
};
