import Card from "../../../../components/common/card/card";
import { DIABLO4_ROUTE } from "../../../../utils/consts";
import GenericSEO from "../../../../components/common/SEO";

export const CHARACTER_SKILLS = [
  {
    name: "Barbarian",
    url: DIABLO4_ROUTE + "/data/skills/barbarian",
    icon: "/images/Diablo4/class_icons/barb.webp",
    iconAlt: "Barbarian Skill Tree icon",
    title: "All Barbarian Skills",
    description: "check out all barbarian skills!",
  },
  {
    name: "Druid",
    url: DIABLO4_ROUTE + "/data/skills/druid",
    icon: "/images/Diablo4/class_icons/druid.webp",
    iconAlt: "Druid Skill Tree icon",
    title: "All Druid Skills",
    description: "check out all druid skills!",
  },
  {
    name: "Necromancer",
    url: DIABLO4_ROUTE + "/data/skills/necromancer",
    icon: "/images/Diablo4/class_icons/necromancer.webp",
    iconAlt: "Necromancer Skill Tree icon",
    title: "All Necromancer Skills",
    description: "check out all necromancer skills!",
  },
  {
    name: "Rogue",
    url: DIABLO4_ROUTE + "/data/skills/rogue",
    icon: "/images/Diablo4/class_icons/rogue.webp",
    iconAlt: "Rogue Skill Tree icon",
    title: "All Rogue Skills",
    description: "check out all rogue skills!",
  },
  {
    name: "Sorcerer",
    url: DIABLO4_ROUTE + "/data/skills/sorcerer",
    icon: "/images/Diablo4/class_icons/sorcerer.webp",
    iconAlt: "Sorcerer Skill Tree icon",
    title: "All Sorcerer Skills",
    description: "check out all sorcerer skills!",
  },
];

export default function SkillTree() {
  return (
    <div className="flex justify-center">
      <GenericSEO
        title="All Class Skills"
        description="Check out all available skills for all of Diablo 4's classes, this
        includes all skills, passive skills, and enhancements."
      />

      <div className="flex flex-col items-center gap-5 border border-zinc-500 bg-zinc-800 py-2 drop-shadow-2xl md:w-3/4">
        <h1 className="font-bold">All Class Skills</h1>

        <div>
          <p className="px-12">
            Check out all available skills for all of Diablo 4&apos;s classes,
            this includes all active skills, passive skills, and enhancements.
          </p>
        </div>

        <div className="md:flex">
          {CHARACTER_SKILLS.map((skill: any) => (
            <Card
              key={skill.name}
              link={skill?.url}
              image={skill?.icon}
              imageAlt={skill?.iconAlt}
              title={skill?.title}
              description={skill?.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
