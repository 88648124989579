import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import { SKILL_TREES } from "../../pages/diablo4/tools/skilltree";
import { DEAD_BY_DAYLIGHT_ROUTE } from "../../utils/consts";
import { TierTypes } from "../../utils/dead-by-daylight/consts";

export default function DeadByDaylightNavbar() {
  const router = useRouter();

  return (
    <div className="text-md flex border-b bg-zinc-800 py-2">
      <div className="flex items-center divide-x">
        <Link
          href={DEAD_BY_DAYLIGHT_ROUTE}
          className={`px-4 ${
            router.pathname == DEAD_BY_DAYLIGHT_ROUTE ? "text-red-500" : ""
          } hover:text-red-500`}
        >
          DBD Toolkit Home
        </Link>

        <div className="group">
          <Link
            href={`${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/`}
            className={`px-4 ${
              router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list`
                ? "text-red-500"
                : ""
            } hover:text-red-500`}
          >
            Tier Lists
          </Link>

          <div className="absolute z-[20] hidden group-hover:block">
            <ul className="mt-2 grid grid-cols-1 gap-1 rounded-md bg-zinc-900 p-2 px-2 md:grid-cols-2">
              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.SURVIVOR_PERKS}`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.asPath ==
                  `${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.SURVIVOR_PERKS}`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Survivor Perks Tier List
              </Link>

              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.KILLER_PERKS}`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.asPath ==
                  `${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.KILLER_PERKS}`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Killer Perks Tier List
              </Link>

              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.KILLERS}`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.asPath ==
                  `${DEAD_BY_DAYLIGHT_ROUTE}/tools/tier-list/${TierTypes.KILLERS}`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Killers
              </Link>
            </ul>
          </div>
        </div>

        <div className="group">
          <Link
            href={`${DEAD_BY_DAYLIGHT_ROUTE}/data`}
            className={`px-4 ${
              router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/data`
                ? "text-red-500"
                : ""
            } hover:text-red-500`}
          >
            Data
          </Link>

          <div className="absolute z-[20] hidden group-hover:block">
            <ul className="mt-2 grid grid-cols-1 gap-1 rounded-md bg-zinc-900 p-2 px-2 md:grid-cols-2">
              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/data/perks`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/data/perks`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Perks
              </Link>

              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/data/survivors`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/data/survivors`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Survivors
              </Link>

              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/data/killers`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/data/killers`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Killers
              </Link>

              <Link
                href={`${DEAD_BY_DAYLIGHT_ROUTE}/data/events`}
                className={`flex border bg-zinc-800 px-2 py-2 ${
                  router.pathname == `${DEAD_BY_DAYLIGHT_ROUTE}/data/events`
                    ? "border-red-500 text-red-500"
                    : ""
                } hover:border-red-500 hover:text-red-500`}
              >
                Events
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
